import * as echarts from "echarts";
import { Search } from '@element-plus/icons-vue';
import request from '@/utils/onlineRequest';
import { ref, reactive, toRefs, defineExpose, inject, watch } from 'vue';
const unwarp = obj => obj && (obj.__v_raw || obj.valueOf() || obj);
export default {
  name: "history",
  props: ['title', 'url', 'otherParams', 'time'],
  setup(props) {
    const theme = inject('theme');
    let themeColor = ref({
      xyAxisLabel: "#E9E9E9",
      //xy轴字体颜色，包括单位颜色
      ayAxisLine: "#465E6A",
      //xy轴颜色
      tooltioBackgroundColor: "#0A4C5D",
      emphasisColor: "#fff"
    });
    if (theme.value == "dark") {
      //绿色系颜色
      themeColor.value.xyAxisLabel = "#E9E9E9";
      themeColor.value.ayAxisLine = "#465E6A";
      themeColor.value.tooltioBackgroundColor = "#0A4C5D";
      themeColor.value.emphasisColor = "#fff";
    } else {
      //白色系
      themeColor.value.xyAxisLabel = "#000";
      themeColor.value.ayAxisLine = "#898d96";
      themeColor.value.tooltioBackgroundColor = "rgba(50,50,50,0.7)";
      themeColor.value.emphasisColor = "#000";
    }
    const state = reactive({
      form: {
        identifier: '',
        //标识符
        deviceId: '',
        queryTime: '1',
        endTime: "",
        startTime: "",
        time: [],
        productModel: '',
        productName: '',
        interval: '',
        functionRule: ''
      },
      searchFormData: [{
        prop: 'queryTime',
        label: 'Time',
        minWidth: '120',
        clearable: false,
        type: 'list',
        options: [{
          dictLabel: '1Hour',
          dictValue: '1'
        }, {
          dictLabel: '24Hour',
          dictValue: '24'
        }, {
          dictLabel: '7Day',
          dictValue: '7'
        }],
        span: 6,
        change: value => {
          if (value) {
            if (state.form.time?.length) {
              state.form.time = [];
            }
          }
          //
        }
      }, {
        prop: 'time',
        label: 'Date',
        type: 'daterange',
        span: 6,
        valueFormat: 'YYYY-MM-DD HH:mm:ss',
        // hideHandle(prop, row) {
        //   return state.form.queryTime != ''
        // },
        change: value => {
          if (value) {
            //  state.form.queryTime = ''
            //  console.log('value: ', value)
            // state.form['startTime'] = state.timeSelect[0];
            // state.form['endTime'] = state.timeSelect[1];
          }
          //
        },
        pickerOptions: {
          disabledDate(time) {
            const beforeDate = new Date(new Date().getTime() - 3600 * 1000 * 24 * 180);
            const year = beforeDate.getFullYear();
            const month = beforeDate.getMonth() + 1;
            const date = beforeDate.getDate();
            const DateTime = new Date(`${year}-${month}-${date} 00:00:00`).getTime();
            const maxTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} 23:59:59`);
            if (time.getTime() > maxTime) {
              return true;
            } else if (time.getTime() < DateTime) {
              return true;
            } else {
              return false;
            }
          }
        }
      }, {
        prop: 'interval',
        label: 'Time interval',
        type: 'list',
        minWidth: '120',
        options: [{
          dictLabel: '1Day',
          dictValue: '1d'
        }, {
          dictLabel: '7Day',
          dictValue: '7d'
        }, {
          dictLabel: 'One month',
          dictValue: '30d'
        }, {
          dictLabel: 'A quarter',
          dictValue: '90d'
        }, {
          dictLabel: 'A year',
          dictValue: '365d'
        }],
        change: value => {
          if (value) {
            if (!state.form.functionRule) {
              state.form.functionRule = 'sum';
            }
          } else {
            state.form.functionRule = '';
          }
        },
        span: 6
      }, {
        prop: 'functionRule',
        label: 'Calculation rule',
        minWidth: '120',
        type: 'list',
        options: [{
          dictLabel: 'sum',
          dictValue: 'sum'
        }, {
          dictLabel: 'avg',
          dictValue: 'avg'
        }, {
          dictLabel: 'max',
          dictValue: 'max'
        }, {
          dictLabel: 'min',
          dictValue: 'min'
        }],
        change: value => {
          if (value) {
            if (!state.form.interval) {
              state.form.interval = '1d';
            }
          } else {
            state.form.interval = '';
          }
        },
        span: 6
      }],
      checkStateShow: false,
      myChart: null,
      unit: null,
      loading: false,
      timeSelect: '',
      showDatePicker: false
    });
    watch(() => {
      return state.form.time;
    }, v => {
      if (v?.length) {
        state.form['startTime'] = v[0];
        state.form['endTime'] = v[1].slice(0, 10) + ' 23:59:59';
        state.form.queryTime = '';
      } else {
        state.form['startTime'] = '';
        state.form['endTime'] = '';
        state.form.queryTime = '1';
      }
    });
    const methods = reactive({
      show(row) {
        state.form.identifier = row.identifier;
        state.form.deviceId = row.deviceId;
        state.form.productName = row.productName;
        state.form.productModel = row.productModel;
        state.unit = row.unit;
        if (Object.keys(props.otherParams).length) {
          for (let key of Object.keys(props.otherParams)) {
            state.form[key] = props.otherParams[key];
          }
        }
        if (props.time) {
          state.form.startTime = props.time.slice(0, 10) + ' 00:00:00';
          state.form.endTime = props.time.slice(0, 10) + ' 23:59:59';
          state.form.time = [props.time, props.time];
          state.form.queryTime = '';
        }

        //
        methods.loadData();
        state.checkStateShow = true;
      },
      loadData() {
        state.loading = true;
        request({
          url: props.url,
          method: 'post',
          data: state.form
        }).then(res => {
          if (res.code === 1000) {
            state.myChart = echarts.init(document.getElementById('chart'));
            state.myChart.clear();
            if (null == res.data.legend) {
              unwarp(state.myChart).setOption({
                grid: {
                  top: '17%',
                  left: '10%',
                  right: '10%',
                  bottom: '10%',
                  containLabel: true
                },
                tooltip: {
                  show: true,
                  trigger: 'axis'
                },
                legend: {
                  orient: 'horizontal',
                  bottom: 10,
                  textStyle: {
                    color: 'gray'
                  },
                  data: [] // '显示'
                },
                xAxis: {
                  axisLine: {
                    lineStyle: {
                      color: 'gray'
                    }
                  },
                  type: 'category',
                  data: []
                },
                yAxis: {
                  axisLine: {
                    lineStyle: {
                      color: 'gray'
                    }
                  },
                  type: 'value',
                  name: ""
                },
                series: [{
                  data: [],
                  //[820, 932, 901,0, 1290, 1330, 1320]
                  type: 'line',
                  name: [],
                  //'显示'
                  smooth: true,
                  color: [themeColor.value.tooltioBackgroundColor]
                }]
              }, true);
            } else {
              chartHandle(res);
            }
          }
        }).finally(() => {
          state.loading = false;
        });
      },
      closeDialog() {
        state.showDatePicker = false;
        state.timeSelect = '';
        state.form.startTime = '';
        state.form.endTime = '';
        state.form.time = [];
        state.myChart?.clear();
        state.checkStateShow = false;
      }
    });

    //
    let radioType = ref('line');

    //
    const chartHandle = res => {
      let legend = [];
      let temp = [];
      legend = res.data.legend;
      let obj = {};
      obj.data = res.data.series[0].data.map(item => {
        if (item.indexOf('+') !== -1) {
          item = item.split('+')[0];
        }
        return item;
      });
      obj.name = res.data.series[0].name;
      if (radioType.value === 'line') {
        obj.type = 'line';
      }
      if (radioType.value === 'bar') {
        obj.type = 'bar';
      }
      if (radioType.value === 'scatter') {
        obj.type = 'scatter';
      }
      obj.smooth = true;
      temp.push(obj);
      let ytype = '';
      unwarp(state.myChart).setOption({
        grid: {
          top: '3%',
          left: '1%',
          right: '1%',
          bottom: '20%',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          // formatter: '{b} <br/>{marker}{a} : {c}',
          formatter: function (params) {
            let result = '';
            params.forEach(function (item) {
              result += item.axisValue + "</br>" + item.marker + item.seriesName + '：' + item.data;
            });
            return result;
          }
        },
        legend: {
          orient: 'horizontal',
          bottom: 50,
          textStyle: {
            color: 'gray'
          },
          data: legend // '显示'
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          textStyle: false
        }, {
          start: 0,
          end: 100,
          textStyle: false
        }],
        xAxis: {
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            color: themeColor.value.xyAxisLabel
          },
          axisLine: {
            lineStyle: {
              color: themeColor.value.ayAxisLine
            }
          },
          splitLine: {
            lineStyle: {
              color: themeColor.value.ayAxisLine
            }
          },
          type: 'category',
          data: res.data.xaxis //['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          nameTextStyle: {
            color: themeColor.value.xyAxisLabel
          },
          axisLabel: {
            color: themeColor.value.xyAxisLabel
          },
          axisLine: {
            lineStyle: {
              color: themeColor.value.ayAxisLine
            }
          },
          splitLine: {
            lineStyle: {
              color: themeColor.value.ayAxisLine
            }
          },
          axisTick: {
            lineStyle: {
              color: themeColor.value.ayAxisLine
            } // 刻度的颜色
          },
          type: ytype,
          name: state.unit
        },
        series: temp
      }, true);
    };
    const radioChange = () => {
      methods.loadData();
    };
    defineExpose({
      show: methods.show
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      radioType,
      radioChange,
      Search,
      theme
    };
  }
};